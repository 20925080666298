import React, { useEffect } from 'react'
import RenderHTML from './RenderHTML'

import JSONData from '../data/landing-page.json'



const Box = ({ children }) => {

    return (
        <div className="md:prose-lg prose-sm break-words">
            <RenderHTML html={children} />
        </div>
    )

}

export default function HeadSection({section}) {
    // console.log(data)
    const [sectionContent, setSectionContent] = React.useState([])

    useEffect(() => {

        const data = JSONData
        setSectionContent(data[section])

    }, [section])

    return (
        <div>


            {sectionContent && (
                <div className='text-white'>
                    <Box>
                        {sectionContent}
                    </Box>
                </div>
            )}


        </div>
    )
}
