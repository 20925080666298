import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

console.log('REACT_APP_LIVE is ', process.env.REACT_APP_LIVE)

let firebaseConfig = {
    apiKey: "AIzaSyALhKOWDiPc83yYGlA6p2vNfH5Po78GJv0",
    authDomain: "wheredoigo-life-staging.firebaseapp.com",
    projectId: "wheredoigo-life-staging",
    storageBucket: "wheredoigo-life-staging.appspot.com",
    messagingSenderId: "245517558985",
    appId: "1:245517558985:web:1b931e5e3e0ba9ac83d918"
};

if (process.env.REACT_APP_LIVE === "true") {
    console.log('LIVE configured')
    firebaseConfig = {
        apiKey: "AIzaSyDQels4YkMLRXWKUBiPwrSL8KJhwZsqSpw",
        authDomain: "wheredoigo-life.firebaseapp.com",
        projectId: "wheredoigo-life",
        storageBucket: "wheredoigo-life.appspot.com",
        messagingSenderId: "520524472051",
        appId: "1:520524472051:web:7d90b322ca4a1b89ea2186",
        measurementId: "G-6ST93L8SVD"
    }
}      

const app = initializeApp(firebaseConfig);

// db = firebase.firestore();

// export default app
// export const db = firebase.firestore();
export const auth = getAuth(app);

export default app