import React, { useRef, useState, useEffect } from "react"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getAuth, sendSignInLinkToEmail, onAuthStateChanged } from "firebase/auth"
import { auth } from './firebase/firebaseConfig';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { gsap } from "gsap";
import HeadSection from "./components/HeadSection";


import './App.css';

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
let actionCodeUrl = 'http://localhost:3000/sign-in'
if (!development) {
  if (process.env.REACT_APP_LIVE === "true") {
    actionCodeUrl = 'https://app.wheredoigo.life/sign-in'
  } else {
    actionCodeUrl = 'https://staging-app.wheredoigo.life/sign-in'
  }
}
console.log('actionCodeUrl is : ', actionCodeUrl)
console.log('process.env.NODE_ENV is : ', process.env.NODE_ENV)

function App() {

  const itemsRef = useRef([]);
  gsap.registerPlugin(ScrollToPlugin);
  // const backgroundRef = useRef(null);
  const [formFeedback, setFormFeedback] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [user, setUser] = React.useState(null);
  const [showSignupModal, setShowSignupModal] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email("must be a valid email").required("email is required"),
  })
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  })
  const onSubmit = (formData) => {

    const email = formData.email
    console.log('email: ', email);
    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, {
      url: actionCodeUrl,
      handleCodeInApp: true,
    })
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email);
        console.log('email sent successfully to : ', email)
        setShowSignupModal(true)
        // setFormFeedback('check your email for a sign in link')
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('error code is : ', errorCode)
        console.log('error message is : ', errorMessage)
        setFormFeedback('There was an error sending the email. please try again')
      });

  }

  useEffect(() => {

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user.email)
      }
    });

    // wait before setting pageLoading to false
    setTimeout(() => {
      setPageLoading(false)
    }
      , 1000)
  }, []);


  const handleScroll = () => {
    itemsRef.current[1].scrollIntoView({ behavior: 'smooth', block: 'start' })
    console.log('clicked to scroll')
  }

  return (
    <>
      <header className="relative z-0 flex items-center justify-center h-screen mb-12 overflow-hidden">
        <div className="relative  z-30 p-5 rounded-xl text-center">
          <div className="item pt-5 " ref={el => itemsRef.current.push(el)}>
            <div className="flex  flex-col justify-between items-center  h-36 justify-between">
              {(!user && !pageLoading) && (
                <>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="text" placeholder="your @ email" className="input input-bordered input-primary w-full max-w-xs" id='user-email-input' {...register("email")} />
                    <div className="h-10  text-red-400 font-bold pb-3">{errors.email?.message}</div>

                    <button className="bg-transparent border-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
                      <code className="text-4xl font-bold text-white">
                        Sign up

                      </code>
                    </button>
                  </form>

                  <div className="container sign-up mx-auto w-blue p-5 text-center  text-white">
                    <div class="flex items-center justify-center pb-5">
                      <HeadSection section={0} />
                    </div>
                  </div>

                </>
              )}
            </div>
          </div>
          {formFeedback && (<div className="text-center text-white font-bold">{formFeedback}</div>)}
          {user && (
            <>
              <div className="font-bold text-white">You are signed in as {user}</div>
              <div className="p-5 text-white">
                <button className="bg-transparent border-4 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" onClick={handleScroll}>
                  <code className="text-4xl font-bold text-white">
                    Read more
                  </code>
                </button>
              </div>
            </>
          )}
        </div>
        <div id="" className="absolute z-1 w-full h-full w-blue opacity-100"></div>
      </header>

      <div id="opening" className="w-blue w-full" ref={el => itemsRef.current.push(el)}>
        <div className="container sign-up mx-auto w-blue p-5 text-center  text-white">
          <div class="flex items-center justify-center pb-5">
            <HeadSection section={1} />
          </div>

          <div class="flex items-center justify-center">
            <HeadSection section={2} />
          </div>
        </div>
      </div>

      <input id="my-modal" type="checkbox" className="modal-toggle" checked={showSignupModal} onChange={e => { }} />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">We've sent you an email!</h3>
          <p className="py-4">check your email for a sign in link and click on it to confirm</p>
          <p className="py-4">if you dont see it, dont forget to check in your spam folder as it may have been put there by your provider</p>
          <div className="modal-action">
            <label onClick={() => { setShowSignupModal(false) }} htmlFor="my-modal" className="btn">Thanks!</label>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
