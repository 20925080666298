import React from 'react'
import ReactMarkdown from 'react-markdown'

export default function RenderMarkdown({ html }) {

    return (

        <ReactMarkdown
            className=' prose lg:prose-xl text-center text-white'
            children={html}
        />    
    )
}