import React, { useRef, useState, useEffect } from "react"
import { useNavigate } from 'react-router-dom';
import { getAuth,  onAuthStateChanged, isSignInWithEmailLink, signInWithEmailLink  } from "firebase/auth"

import { useLocation } from 'react-router-dom';

import './App.css';

function SignIn() {

      const itemsRef = useRef([]);
      const [formFeedback, setFormFeedback] = useState(null);
      const [user, setUser] = React.useState(null);
      const location = useLocation();
      const auth = getAuth();
      const navigate = useNavigate();


      useEffect(() => {

        onAuthStateChanged(auth, (user) => {
          if (user) {
            setUser(user.email)
          }
        });

      }, [auth]);

      // const handleSignOut = () => {
      //   const auth = getAuth();
      //   signOut(auth).then(() => {
      //     console.log('signed out')
      //     setFormFeedback('signed out')
      //     setUser(null)
      //   }).catch((error) => {
      //     // An error happened.
      //     console.log('error signing out : ', error)
      //     setFormFeedback('error signing out')
      //   });
      // }

      useEffect(() => {

        const realUrl = location.pathname + location.search
    
        if (isSignInWithEmailLink(auth, realUrl)) {
          let email = window.localStorage.getItem('emailForSignIn');
          if (!email) {
            email = window.prompt('Please provide your email for confirmation');
          }
    
          signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
              window.localStorage.removeItem('emailForSignIn');
              // go to home page
            //   navigate("/");
            // wait for 5 seconds before navigating
            setTimeout(() => {
              navigate("/");
            }, 5000)

            })
            .catch((error) => {
              console.log('error : ', error)
              setFormFeedback('error signing in ' + error)
            });
        } else {
          console.log('not a sign in link', realUrl)
        }
      }, [location.pathname, location.search, auth, navigate])
    

    return (
        <>
            <header className="relative z-0 flex items-center justify-center h-screen mb-12 overflow-hidden">
                <div className="relative  z-30 p-5 rounded-xl text-center">
                    <div className="item pt-5 " ref={el => itemsRef.current.push(el)}>
                        <div className="flex  flex-col justify-between items-center  h-36 justify-between">

                            <h1 className="text-white text-3xl">Sign In</h1>
                        </div>
                    </div>
                    {user && (
                        <>
                            <div className="font-bold text-white">You are signed in as {user}
                            </div>
                            {formFeedback && (<div className="text-center text-white font-bold">{formFeedback}</div>)}

                            {/* <button className="btn btn-primary mt-5 " onClick={handleSignOut}>sign out </button> */}
                        </>
                    )}
                </div>
                <div id="" className="absolute z-1 w-full h-full w-blue opacity-100"></div>
            </header>
            <div className="container sign-up mx-auto w-blue p-5 h-screen text-center">
                <h1 className="mt-10 mb-4 text-4xl  pb-10">Join the W Set</h1>
                <h1 className="pt-10 mb-4">In Chancery
                </h1>
                <p className="mb-4">London. Michaelmas term lately over, and the Lord Chancellor sitting in Lincoln's Inn Hall. Implacable November weather. As much mud in the streets as if the waters had but newly retired from the face of the earth, and it would not be wonderful to meet a Megalosaurus, forty feet long or so, waddling like an elephantine lizard up Holborn Hill. Smoke lowering down from chimney-pots, making a soft black drizzle, with flakes of soot in it as big as full-grown snowflakes—gone into mourning, one might imagine, for the death of the sun. Dogs, undistinguishable in mire. Horses, scarcely better; splashed to their very blinkers. Foot passengers, jostling one another's umbrellas in a general infection of ill temper, and losing their foot-hold at street-corners, where tens of thousands of other foot passengers have been slipping and sliding since the day broke (if this day ever broke), adding new deposits to the crust upon crust of mud, sticking at those points tenaciously to the pavement, and accumulating at compound interest.</p>
            </div>
        </>
    );
}

export default SignIn;
